
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(85);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Promocoes
        $(document).on('change', '#select-unidades', function(event) {
            event.preventDefault();

            id = $(this).val();
            
            $('.Chamadas._cardapio').addClass('_hide');
            $('#unidade-' + id).removeClass('_hide');
        });

        // Popup
        if (!localStorage.hasOwnProperty('show_popup')) {
            if ($('.Popup').length > 0) {
                $.magnificPopup.open({
                    items: {
                        src: '.Popup'
                    },
                    type: 'inline'
                }, 0);

                localStorage.setItem('show_popup', true);
            }
        }
    });
});